<template>
  <div>
    <h2>List of all employees</h2>
    <hr />
    <br />
    <table class="table is-fullwidth">
      <thead>
        <tr>
          <th>EmployeeID</th>
          <th>Name</th>
          <th>Email</th>
          <th>Mobile</th>
          <th>status</th>
          <th>Generate Code</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in datas" :key="i._id">
          <td>{{ i.empId }}</td>
          <td>{{ i.name }}</td>
          <td>{{ i.email }}</td>
          <td>{{ i.mobile }}</td>
          <td>{{ i.status }}</td>
          <td>
            <button class="button primary" @click="code(i._id)">
              Generate Code
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  data: () => ({
    datas: [],
  }),
  methods: {
    code(id) {
      this.$Axios.post(this.URL + "generate-code/" + id).then((response) => {
        this.getCode = response.data.LoginCode
        this.$swal.fire(
          "Generated succeed!",
          `Login code is: ${this.getCode}, 
          Actually this code automatically send to this employee by SMS`,
          "success"
        );
      });
    },
    fetchdate() {
      this.$Axios.get(this.URL + "getuserinfo").then((response) => {
        this.datas = response.data.getUser;
      });
    },
  },

  created() {
    this.fetchdate();
  },
};
</script>
<style scoped>
tr:hover {
  background-color: #f5f5f5;
}
table,
td,
th {
  border: 1px solid black;
  text-align: left;
}

table {
  font-family: Saysettha ot;
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 08px;
}
button.primary:hover {
  background: seagreen;
  color: #f5f5f5;
}
button.primary {
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  border: none;
  height: 2.5em;
  font-size: 14px;
  box-shadow: none;
  cursor: pointer;
  background-color: mediumturquoise;
}
</style>